import { NEEDS_STOCKING } from "../t-shirt/TShirtsStockingStatus";
import AppUserGroups from "../../../../services/AppUserGroups";

const INVENTORY = {
    name: "Inventory",
    canSelect: true,
    checkDisabled: () => false,
    canAccess: (userGroups) => AppUserGroups.hasAdminAccess(userGroups),
};

const STOCKING = {
    name: "Stocking",
    canSelect: true,
    canAccess: (userGroups) => AppUserGroups.hasManagerAccess(userGroups),
    checkDisabled: ({ mode, restaurant }) => {
        if (
            mode &&
            mode.name === INVENTORY.name &&
            restaurant &&
            restaurant.selectedTShirt &&
            restaurant.selectedTShirt.status
        ) {
            return restaurant.selectedTShirt.status.name !== NEEDS_STOCKING.name;
        }
        return false;
    },
};

export { STOCKING, INVENTORY };
