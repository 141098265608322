import MenusSectionConfigs from "../config/MenusSectionConfigs";

function findMenuItem(menu, menuItemName) {
    return menu.find((menuItem) => menuItem.data.name === menuItemName);
}

function findMenuItemIndex(menu, menuItemName) {
    return menu.findIndex((menuItem) => menuItem.data.name === menuItemName);
}

function digestUploadedSales(sectionName, data, menu, saleType, day, lastUpdatedBy, lastUpdatedAt) {
    return data.reduce(
        (acc, uploadedSale) => {
            const [name, quantitySold] = uploadedSale;
            const menuItemIndex = findMenuItemIndex(acc.updatedMenu, name, saleType);
            const menuItem = findMenuItem(acc.updatedMenu, name, saleType);

            if (!menuItem) {
                acc.invalidQueue.push(uploadedSale);
                return acc;
            }

            // item need update
            if (menuItem.sales && menuItem.sales[saleType]) {
                menuItem.sales[saleType].data.quantity = quantitySold;
                menuItem.sales[saleType].data.lastUpdatedBy = lastUpdatedBy;
                menuItem.sales[saleType].data.lastUpdatedAt = lastUpdatedAt;
                acc.updateQueue.push(menuItem.sales[saleType]);
                return acc;
            }

            // item doesn't have a value
            const { attachMenuItemId } = MenusSectionConfigs.getUtils(sectionName);
            const { menuItemSaleResource } = MenusSectionConfigs.getConfig(sectionName).resources;
            menuItem.sales[saleType] = new menuItemSaleResource(
                attachMenuItemId(
                    {
                        day: day.data.time,
                        type: saleType,
                        quantity: quantitySold,
                        lastUpdatedBy,
                        lastUpdatedAt,
                    },
                    menuItem.data.id
                )
            );
            acc.createQueue.push(menuItem.sales[saleType]);

            acc.updatedMenu[menuItemIndex] = menuItem;
            return acc;
        },
        { updateQueue: [], createQueue: [], invalidQueue: [], updatedMenu: menu }
    );
}

function displayRecipeItem(recipeItem) {
    const quantity = recipeItem.data.quantity;
    const name = recipeItem.ingredient.data.name;

    return `${quantity} x ${name}`;
}

function getMenuItemCompletionScore({ sales: { SINGLE, MULTIPLE } }) {
    return !!SINGLE + !!MULTIPLE;
}

function getMenuCompletionScore(menu) {
    const menuItemsCompletionScore = menu
        .map(getMenuItemCompletionScore)
        .reduce((a, c) => a + c, 0);
    return menuItemsCompletionScore / (2 * menu.length);
}

function getTotalCompletionScore(restaurants) {
    const maxCompletionScore = restaurants.reduce((acc, { menu }) => {
        acc += 2 * menu.length;
        return acc;
    }, 0);

    const actualCompletionScore = restaurants.reduce((acc, { menu }) => {
        acc += getMenuCompletionScore(menu);
        return acc;
    }, 0);

    return actualCompletionScore / maxCompletionScore;
}

const MenusUtils = {
    digestUploadedSales,
    displayRecipeItem,
    getMenuCompletionScore,
    getTotalCompletionScore,
};

export default MenusUtils;
