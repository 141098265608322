import CacheDataTerms from "./CacheDataTerms";
import ClearedChars from "./ClearedChars";

function filterRows(salesData) {
    return salesData.filter((sale) => sale.length === 8 && sale[0].length !== 0);
}

function parseRawSales(salesData) {
    const headerIndex = salesData.findIndex((sale) => {
        return sale.length > 6 && sale[0].toLowerCase() === "group";
    });
    const header = salesData[headerIndex];
    const [menuItemNameIndex, quantitySoldIndex] = extractTargetColumnsIndex(header);
    return salesData.slice(1).map((s) => [s[menuItemNameIndex], parseFloat(s[quantitySoldIndex])]);

    /* ---------- IMPL ---------- */
    function extractTargetColumnsIndex(header) {
        return [
            header.findIndex((item) => item.toLowerCase() === "Group".toLowerCase()),
            header.findIndex((item) => item.toLowerCase() === "Qty Sold".toLowerCase()),
        ];
    }
}

function sortParsedSales(salesData) {
    return salesData.sort((a, b) => a[0].localeCompare(b[0]));
}

function filterSortedSales(salesData) {
    return salesData.filter((item) => item[0] !== "");
}

const clearIllegalChars = (data) => {
    let joinResult = false;
    if (typeof data === "string") {
        data = data.split("");
        joinResult = true;
    }

    let result = data.filter((c) => !ClearedChars.includes(c)).map((x) => x.toLowerCase());

    if (result.length > 0 && result.join("") !== data.join("")) {
        CacheDataTerms.in[result.join("")] = data.join("");
        CacheDataTerms.out[data.join("")] = result.join("");
    }
    return joinResult ? result.join("") : result;
};

function mergeFilteredSales(salesData) {
    return salesData.map((s) => s).reduce(mergeSameSales, []);

    /* ---------- IMPL ---------- */
    function mergeSameSales(acc, curr) {
        const isSameAsAccHead =
            acc.length > 0 &&
            clearIllegalChars(acc[acc.length - 1][0]) === clearIllegalChars(curr[0]);
        if (isSameAsAccHead) {
            acc[acc.length - 1][1] += curr[1];
            console.log(curr[0]);
        } else {
            acc.push(curr);
        }
        return acc;
    }
}

const SalesParser = {
    filterRows,
    parseRawSales,
    sortParsedSales,
    filterSortedSales,
    mergeFilteredSales,
};

export default SalesParser;
