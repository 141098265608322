import { OffSiteTransactionStatuses } from "./../OffSiteResources";
import {
    OffSiteTaskType,
    OffSiteTasksByStatusAndIdAndRestType,
    OffSiteInitDataType,
    OffSiteContextStateType,
    OffSiteTasksByIdAndRestType,
    OffSiteTasksByRestIdType,
    OffSiteTasksByRestType,
} from "./../context/OffSiteContextTypes";
import { ResourceRequest } from "./../../../resources/ResourceTypes";
import { fetchResources } from "../../../resources/ResourcesService";
import AppUtilsService from "../../../services/AppUtilsService";
import { AppRestaurant, AppRestaurantTypes } from "../../admin/AdminResources";
import {
    AppOffSiteArea,
    AppOffSiteItem,
    AppOffSiteTransaction,
    AppOffSiteTransactionStatuses,
    AppOffSiteTransactionTypes,
} from "../OffSiteResources";
import _ from "lodash";
import { Resource } from "../../../resources/Resource";

async function getOffSiteTasks(filter: any): Promise<AppOffSiteTransaction[]> {
    let tasks: AppOffSiteTransaction[] = [];

    try {
        const offSiteTransactionRequest: ResourceRequest = [AppOffSiteTransaction, filter];
        const tasksResponse = await fetchResources([offSiteTransactionRequest]);
        tasks = tasksResponse[0];
    } catch (e) {
        console.error("Cannot load tasks");
    }

    return tasks;
}

async function getOffSiteOpenDeliveryTasks() {
    const offSiteIncompleteTasksFilter = {
        and: [
            { type: { eq: AppOffSiteTransactionTypes.DELIVERY } },
            { status: { ne: AppOffSiteTransactionStatuses.COMPLETED } },
            { status: { ne: AppOffSiteTransactionStatuses.CANCELLED } },
        ],
    };

    return await getOffSiteTasks(offSiteIncompleteTasksFilter);
}

async function getOffSiteRestaurants(): Promise<AppRestaurant[]> {
    const [restaurants] = await fetchResources([[AppRestaurant]]);
    return restaurants;
}

function getOffSiteRestaurantsById(restaurants: AppRestaurant[] = []) {
    const restaurantsById = restaurants.reduce(AppUtilsService.groupDataByItem("id"), {});
    return restaurantsById;
}

async function getOffSiteAreasById() {
    const [offSiteAreas] = await fetchResources([[AppOffSiteArea]]);
    const offSiteAreasById = offSiteAreas.reduce(AppUtilsService.groupDataByItem("id"), {});
    return offSiteAreasById;
}

async function getOffSiteItemsById() {
    const [offSiteItems] = await fetchResources([[AppOffSiteItem]]);
    const offSiteItemsById = offSiteItems.reduce(AppUtilsService.groupDataByItem("id"), {});
    return offSiteItemsById;
}

async function getOffSiteOpenDeliveryTasksDecorated(): Promise<OffSiteInitDataType> {
    const result: OffSiteInitDataType = { offSiteTasks: [], destinationRestaurants: [] };

    const restaurants: AppRestaurant[] = await getOffSiteRestaurants();
    const restaurantsById = getOffSiteRestaurantsById(restaurants);
    const offSiteAreasById = await getOffSiteAreasById();
    const offSiteItemsById = await getOffSiteItemsById();
    const rawOffSiteTasks = await getOffSiteOpenDeliveryTasks();

    // building response
    result.offSiteTasks = rawOffSiteTasks.map((rawOffSiteTask: AppOffSiteTransaction) => {
        const destinationRestaurant = restaurantsById[rawOffSiteTask.data.restaurantId];
        const offSiteItem = offSiteItemsById[rawOffSiteTask.data.offSiteItemId];
        const offSiteArea = offSiteAreasById[offSiteItem?.data?.offSiteAreaId];
        const offSiteRestaurant = restaurantsById[offSiteArea?.data?.restaurantId];

        const offSiteTask: OffSiteTaskType = Object.assign(rawOffSiteTask, {
            destinationRestaurant,
            offSiteItem,
            offSiteArea,
            offSiteRestaurant,
        });

        return offSiteTask;
    });

    result.destinationRestaurants = restaurants.filter((restaurant: AppRestaurant) => {
        return (
            restaurant.data.type === AppRestaurantTypes.COMMISSARY ||
            restaurant.data.type === AppRestaurantTypes.RESTAURANT
        );
    });

    return result;
}

function getStatusTasksCount(offSiteStatusTasksByIdAndRest: OffSiteTasksByIdAndRestType) {
    if (!offSiteStatusTasksByIdAndRest) {
        return 0;
    }

    return Object.values(offSiteStatusTasksByIdAndRest)
        .map((tasksByRestaurantId) => Object.values(tasksByRestaurantId))
        .flat()
        .flat().length;
}

function getPendingTasksCount(offSiteTasks: OffSiteTasksByStatusAndIdAndRestType) {
    return getStatusTasksCount(offSiteTasks[AppOffSiteTransactionStatuses.PENDING]);
}

function getLoadedTasksCount(offSiteTasks: OffSiteTasksByStatusAndIdAndRestType) {
    return getStatusTasksCount(offSiteTasks[AppOffSiteTransactionStatuses.LOADED]);
}

function getTransitTasksCount(offSiteTasks: OffSiteTasksByStatusAndIdAndRestType) {
    return getStatusTasksCount(offSiteTasks[AppOffSiteTransactionStatuses.TRANSIT]);
}

function sortTasksByCreatedAt(taskA: OffSiteTaskType, taskB: OffSiteTaskType) {
    return taskB.data.createdAt - taskA.data.createdAt;
}

function getLatestPendingTask(pendingTasks: OffSiteTaskType[]) {
    pendingTasks.sort(sortTasksByCreatedAt);
    const latestTask = pendingTasks[0];
    latestTask.duplicates = pendingTasks.splice(1);
    return latestTask;
}

function deDupePendingTasks(
    offSitePendingTasksByIdAndRest: OffSiteTasksByIdAndRestType
): OffSiteTasksByIdAndRestType {
    Object.keys(offSitePendingTasksByIdAndRest).forEach((offSiteItemId) => {
        Object.keys(offSitePendingTasksByIdAndRest[offSiteItemId]).forEach((restaurantId) => {
            offSitePendingTasksByIdAndRest[offSiteItemId][restaurantId] = [
                getLatestPendingTask(offSitePendingTasksByIdAndRest[offSiteItemId][restaurantId]),
            ];
        });
    });

    return offSitePendingTasksByIdAndRest;
}

function buildOffSiteTasksByStatusAndIdAndRest(): OffSiteTasksByStatusAndIdAndRestType {
    return {
        PENDING: {},
        CANCELLED: {},
        LOADED: {},
        TRANSIT: {},
        COMPLETED: {},
    };
}

function organizeOffSiteTasksByStatusAndId(
    offSiteTasks: OffSiteTaskType[] = []
): OffSiteTasksByStatusAndIdAndRestType {
    let offSiteTasksByStatusAndIdAndRest = buildOffSiteTasksByStatusAndIdAndRest();

    offSiteTasks.forEach((task) => {
        const status = task.data.status as keyof OffSiteTransactionStatuses;
        const offSiteItemId: string | number = task.data.offSiteItemId;
        const restaurantId: string | number = task.data.restaurantId;

        // init or keep status
        offSiteTasksByStatusAndIdAndRest[status] = offSiteTasksByStatusAndIdAndRest[status] || {};

        // init or keep offSiteItemId
        if (!offSiteTasksByStatusAndIdAndRest[status][offSiteItemId]) {
            offSiteTasksByStatusAndIdAndRest[status][offSiteItemId] = {};
        }

        // init or keep restaurantId
        if (!offSiteTasksByStatusAndIdAndRest[status][offSiteItemId][restaurantId]) {
            offSiteTasksByStatusAndIdAndRest[status][offSiteItemId][restaurantId] = [];
        }

        // add task
        offSiteTasksByStatusAndIdAndRest[status][offSiteItemId][restaurantId].push(task);
    });

    offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.PENDING] = deDupePendingTasks(
        offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.PENDING]
    );

    return offSiteTasksByStatusAndIdAndRest;
}

// TODO: remove "any" after OnHand is typescript-ified
function findLocationOfTaskByKey(restaurants: any[], taskSelectedKey: string) {
    let response = {
        restaurant: null,
        group: null,
        item: null,
    };

    if (!restaurants || !taskSelectedKey) {
        return response;
    }

    Object.values(restaurants).forEach((restaurant) => {
        restaurant.groups.forEach((group: any) => {
            group.items.forEach((item: any) => {
                if (item.data.id === taskSelectedKey) {
                    response = { ...response, restaurant, group, item };
                }
            });
        });
    });

    return response;
}

async function loadOffSiteItemById(offSiteItemId: string | number) {
    const [offSiteItem] = await fetchResources([[AppOffSiteItem, { id: { eq: offSiteItemId } }]]);
    return offSiteItem ? offSiteItem[0] : null;
}

function extractSelectedOffSiteItem(offSiteState: OffSiteContextStateType): OffSiteTaskType | null {
    if (!offSiteState.taskSelectedKey) {
        return null;
    }

    if (!offSiteState.offSiteTasksByStatusAndIdAndRest) {
        return null;
    }

    const pendingSelectedKeyItem =
        offSiteState.offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.PENDING][
            offSiteState.taskSelectedKey
        ];

    if (!Array.isArray(pendingSelectedKeyItem)) {
        return null;
    }

    return pendingSelectedKeyItem[0].offSiteItem;
}

function getTasksFromOffSiteTasks(offSiteTasks: any) {
    if (!offSiteTasks) {
        return [];
    }
    return Object.values(offSiteTasks)
        .map((itemTasksByRestaurant: any) => Object.values(itemTasksByRestaurant))
        .flat();
}

function getTasksByDestinationRestaurantId(
    offSiteTasksByStatusAndIdAndRest: OffSiteTasksByStatusAndIdAndRestType
): OffSiteTasksByRestIdType {
    const tasksByDestinationRestaurantId: OffSiteTasksByRestIdType = {};

    Object.values(offSiteTasksByStatusAndIdAndRest).forEach(
        (offSiteStatusTasksByIdAndRest: OffSiteTasksByIdAndRestType) => {
            Object.values(offSiteStatusTasksByIdAndRest).forEach(
                (offSiteStatusAndIdTasksByRest) => {
                    Object.keys(offSiteStatusAndIdTasksByRest).forEach((restaurantId) => {
                        if (!tasksByDestinationRestaurantId[restaurantId]) {
                            tasksByDestinationRestaurantId[restaurantId] = [];
                        }
                        tasksByDestinationRestaurantId[restaurantId] =
                            tasksByDestinationRestaurantId[restaurantId].concat(
                                offSiteStatusAndIdTasksByRest[restaurantId]
                            );
                    });
                }
            );
        }
    );

    return tasksByDestinationRestaurantId;
}

function extractDestinationRestaurants(tasks: any) {
    const destinationRestaurants: AppRestaurant[] = tasks.reduce(
        (destinationRestaurants: any, task: any) => {
            destinationRestaurants[task?.destinationRestaurant?.data?.id] =
                task?.destinationRestaurant;
            return destinationRestaurants;
        },
        {}
    );

    return Object.values(destinationRestaurants).sort((a: any, b: any) => a.data.id - b.data.id);
}

// Consider the "local" (on hand context item.deliveryQuantity) value first
function getDefaultPendingAmountForSelectedItem(
    offSiteTasksByStatusAndIdAndRest: OffSiteTasksByStatusAndIdAndRestType,
    item: any
) {
    const selectedItemTasksByRest: OffSiteTasksByRestIdType =
        offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.PENDING][item.data.id];

    if (!selectedItemTasksByRest) {
        return 0;
    }

    const tasks = Object.values(selectedItemTasksByRest).flat();

    return (
        tasks.reduce((sum: number, offSiteTask: OffSiteTaskType) => {
            return sum + (offSiteTask.data.quantity || 0);
        }, 0) || 0
    );
}

function hasExactRequiredValue(
    offSiteTasksByStatusAndIdAndRest: OffSiteTasksByStatusAndIdAndRestType,
    item: any
) {
    if (typeof item.deliveryQuantity !== "number") {
        return false;
    }
    return (
        item.deliveryQuantity > 0 &&
        item.deliveryQuantity ===
            getDefaultPendingAmountForSelectedItem(offSiteTasksByStatusAndIdAndRest, item)
    );
}

function hasValidValue(item: any) {
    return typeof item?.deliveryQuantity === "number" && item.deliveryQuantity !== 0;
}

function getUnitDisplay(item: any) {
    return item?.offSiteUnit?.data?.name || "Each";
}

async function fetchItemTransactions(offSiteItemId: string | number) {
    const [itemTransactions] = await fetchResources([
        [AppOffSiteTransaction, { offSiteItemId: { eq: offSiteItemId } }],
    ]);
    return itemTransactions;
}

async function loadReceptionData() {
    let [transitTasks, offSiteItems, destinationRestaurants] = await fetchResources([
        [
            AppOffSiteTransaction,
            {
                and: [
                    { type: { eq: AppOffSiteTransactionTypes.DELIVERY } },
                    { status: { eq: AppOffSiteTransactionStatuses.TRANSIT } },
                ],
            },
        ],
        [AppOffSiteItem],
        [
            AppRestaurant,
            {
                or: [
                    { type: { eq: AppRestaurantTypes.RESTAURANT } },
                    { type: { eq: AppRestaurantTypes.COMMISSARY } },
                ],
            },
        ],
    ]);

    const offSiteItemsById = offSiteItems.reduce(AppUtilsService.groupDataByItem("id"), {});
    transitTasks = transitTasks.map((task: OffSiteTaskType) => {
        task.offSiteItem = offSiteItemsById[task.data.offSiteItemId];
        return task;
    });

    const transitTasksByRestaurantId = transitTasks.reduce(
        AppUtilsService.groupDataByList("restaurantId"),
        {}
    );
    Object.keys(transitTasksByRestaurantId).forEach((restaurantId) => {
        transitTasksByRestaurantId[restaurantId] = transitTasksByRestaurantId[restaurantId].reduce(
            AppUtilsService.groupDataByItem("offSiteItemId"),
            {}
        );
    });

    return { transitTasksByRestaurantId, destinationRestaurants };
}

function sumUpItemStock(completedTransactions: AppOffSiteTransaction[] = []) {
    return completedTransactions.reduce((sum, transaction) => {
        if (transaction.data.type === AppOffSiteTransactionTypes.DELIVERY) {
            sum -= transaction.data.quantity || 0;
        }
        if (transaction.data.type === AppOffSiteTransactionTypes.STOCKING) {
            sum += transaction.data.quantity || 0;
        }
        return sum;
    }, 0);
}

async function getOffSiteItemInStockAmount(offSiteItemId: string | number | undefined) {
    if (!offSiteItemId) {
        return 0;
    }
    const [completedTransactionsForItem] = await fetchResources([
        [
            AppOffSiteTransaction,
            {
                and: [
                    { offSiteItemId: { eq: offSiteItemId } },
                    { status: { eq: AppOffSiteTransactionStatuses.COMPLETED } },
                ],
            },
        ],
    ]);

    if (!Array.isArray(completedTransactionsForItem) || completedTransactionsForItem.length === 0) {
        return "?";
    }

    const inStockAmount = sumUpItemStock(completedTransactionsForItem);

    return inStockAmount;
}

function sortTasksKeys(taskKeyA: string | number, taskKeyB: string | number) {
    return +taskKeyA - +taskKeyB;
}

function findTaskSelectedKeyIndex(
    tasksKeys: (string | number)[],
    taskSelectedKey: string | number
) {
    return tasksKeys
        .sort(sortTasksKeys)
        .findIndex((taskKey: string | number) => taskKey === taskSelectedKey);
}

function getTaskKeyStep(
    offSiteStatusTasks: OffSiteTasksByIdAndRestType,
    taskSelectedKey: string | number,
    step = 0
): string {
    const tasksKeys = Object.keys(offSiteStatusTasks);
    const firstTaskKey = tasksKeys[0];

    if (!taskSelectedKey) {
        return firstTaskKey;
    }

    const taskSelectedKeyIndex = findTaskSelectedKeyIndex(tasksKeys, taskSelectedKey);

    if (taskSelectedKeyIndex === -1) {
        return firstTaskKey;
    }

    const stepTasksKeysIndex = (taskSelectedKeyIndex + step) % tasksKeys.length;
    const stepTasksKey = tasksKeys[stepTasksKeysIndex];

    return stepTasksKey;
}

function getPrevPendingTaskKey(
    offSiteTasksByStatusAndIdAndRest: OffSiteTasksByStatusAndIdAndRestType,
    taskSelectedKey?: string
): string | null {
    taskSelectedKey =
        taskSelectedKey ||
        _.last(
            Object.keys(offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.PENDING])
        );

    if (!taskSelectedKey) {
        return null;
    }

    return getTaskKeyStep(
        offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.PENDING],
        taskSelectedKey,
        -1
    );
}

function getNextPendingTaskKey(
    offSiteTasksByStatusAndIdAndRest: OffSiteTasksByStatusAndIdAndRestType,
    taskSelectedKey?: string
): string | null {
    taskSelectedKey =
        taskSelectedKey ||
        _.first(
            Object.keys(offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.PENDING])
        );

    if (!taskSelectedKey) {
        return null;
    }

    return getTaskKeyStep(
        offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.PENDING],
        taskSelectedKey,
        1
    );
}

function getOffSitePendingItemForSelectedTask(
    offSiteTasksByStatusAndIdAndRest: OffSiteTasksByStatusAndIdAndRestType,
    taskSelectedKey: string | number
): Resource | undefined {
    let result: Resource | undefined;

    const pendingTasksForItem: OffSiteTasksByRestType =
        offSiteTasksByStatusAndIdAndRest[AppOffSiteTransactionStatuses.PENDING][taskSelectedKey];

    const pendingTasksForItemList = Object.values(pendingTasksForItem || {}).flat();
    result = pendingTasksForItemList[0]?.offSiteItem;

    return result;
}

function sortOffSiteTasksByStatus(
    transactionTaskA: AppOffSiteTransaction,
    transactionTaskB: AppOffSiteTransaction
) {
    const statusesSorted = [
        AppOffSiteTransactionStatuses.TRANSIT,
        AppOffSiteTransactionStatuses.LOADED,
        AppOffSiteTransactionStatuses.PENDING,
    ];
    const statusAIndex = statusesSorted.findIndex(
        (status) => status === transactionTaskA.data.status
    );
    const statusBIndex = statusesSorted.findIndex(
        (status) => status === transactionTaskB.data.status
    );

    return statusAIndex - statusBIndex;
}

const OffSiteTasksUtils = {
    getOffSiteOpenDeliveryTasksDecorated,
    getPendingTasksCount,
    getLoadedTasksCount,
    getTransitTasksCount,
    organizeOffSiteTasksByStatusAndId,
    findLocationOfTaskByKey,
    loadOffSiteItemById,
    extractSelectedOffSiteItem,
    getTasksFromOffSiteTasks,
    getTasksByDestinationRestaurantId,
    extractDestinationRestaurants,
    getDefaultPendingAmountForSelectedItem,
    hasExactRequiredValue,
    hasValidValue,
    getUnitDisplay,
    fetchItemTransactions,
    getOffSiteOpenDeliveryTasks,
    loadReceptionData,
    getOffSiteItemInStockAmount,
    sumUpItemStock,
    getPrevPendingTaskKey,
    getNextPendingTaskKey,
    getOffSitePendingItemForSelectedTask,
    sortOffSiteTasksByStatus,
};

export default OffSiteTasksUtils;
