import { seriesPromises } from "../../../resources/ResourcesService";
import {
    AppOffSiteTransaction,
    AppOffSiteTransactionStatuses,
    AppOffSiteTransactionTypes,
} from "../../off-site/OffSiteResources";

function extractInventoryQty(order) {
    if (typeof order?.inventory?.data?.quantity !== "number") {
        return null;
    }
    return order?.inventory?.data?.quantity || 0;
}

function extractConversion(order) {
    const onHandUnitName = order.onHandUnit.data.name;
    const deliveryUnitName = order.deliveryUnit.data.name;
    return onHandUnitName === deliveryUnitName ? 1 : order.data.conversion;
}

function extractDeliveryQty(order) {
    if (typeof order.editedQuantity === "number") {
        return order.editedQuantity;
    }
    const inventoryQty = extractInventoryQty(order);
    if (inventoryQty === null) {
        return null;
    }
    const conversion = extractConversion(order);
    return Math.ceil((order.data.par - inventoryQty) / conversion);
}

function extractOrderName(order) {
    return order?.data?.name;
}

function extractDeliveryUnitName(order) {
    return order?.deliveryUnit?.data?.name || "No data";
}

function isValidState({ restaurant }) {
    return restaurant?.displayVendor?.data?.name;
}

function filterForValidDelivery(order) {
    const inventoryQty = extractInventoryQty(order);
    const deliveryQuantity = extractDeliveryQty(order);

    return inventoryQty !== null && deliveryQuantity > 0;
}

function filterOutAllVendorsOption({ data: { id } }) {
    return id !== -1;
}

function findIndexOfSelectedVendor(vendors, selected) {
    return vendors.findIndex((vendor) => vendor?.data?.id === selected?.data?.id);
}

function clearVendorSelection(vendors) {
    return vendors.map((vendor) => {
        vendor.isSelected = false;
        return vendor;
    });
}

function findSelectedVendor(vendors) {
    if (!Array.isArray(vendors)) {
        return;
    }

    const selectedVendors = vendors.filter(({ isSelected }) => isSelected);
    if (selectedVendors.length > 1) {
        DeliveryOrdersUtils.clearVendorSelection(vendors);
        return null;
    }
    if (selectedVendors.length === 1) {
        return selectedVendors[0];
    }
    return null;
}

function extractDeliveryDisplay(order) {
    return `${extractDeliveryQty(order)} ${extractDeliveryUnitName(order)}`;
}

function getOrderQuantity(order) {
    return typeof order.editedQuantity === "number" ? order.editedQuantity : order.deliveryQuantity;
}

async function createOrdersOffSiteTransactions(userName, restaurant, vendor, orders) {
    const transactionsData = orders
        .map((order) => buildOrderOffSiteTransaction(userName, restaurant, vendor, order))
        .filter((x) => x);
    const transactionCreationPromises = transactionsData.map((transactionData) =>
        AppOffSiteTransaction.getModel().api.create(transactionData)
    );

    try {
        await seriesPromises(transactionCreationPromises);

        console.log("Created transactions!");
    } catch (e) {
        throw new Error(`Failed to create transactions! ${e.message}`);
    }
}

function buildOrderOffSiteTransaction(userName, restaurant, vendor, order) {
    if (!order?.data?.offSiteItemId || !restaurant?.data?.id) {
        return null;
    }

    return {
        offSiteItemId: order?.data?.offSiteItemId,
        type: AppOffSiteTransactionTypes.DELIVERY,
        status: AppOffSiteTransactionStatuses.PENDING,
        restaurantId: restaurant?.data?.id,
        quantity: getOrderQuantity(order),
        createdBy: userName,
    };
}

function isNonEmptyOrder({ editedQuantity, deliveryQuantity }) {
    return typeof editedQuantity === "number" ? editedQuantity > 0 : deliveryQuantity > 0;
}

function extractOrders(vendor) {
    if (!Array.isArray(vendor.deliveryOrders) || !Array.isArray(vendor.addedOrders)) {
        console.error("invalid vendor");
        return [];
    }
    return [...vendor.deliveryOrders, ...vendor.addedOrders];
}

function extractNonEmptyOrders(vendor) {
    return extractOrders(vendor).filter(isNonEmptyOrder);
}

function sortOrders(a, b) {
    return a?.data?.name.localeCompare(b?.data?.name);
}

function isOffSiteVendor(vendor) {
    return vendor?.data?.isOffSite === 1;
}

function filterDeliveryOrders(emailVendor, order) {
    // filter out those that are already in emailVendor.deliveryOrders or emailVendor.addedOrders
    const isInDeliveryOrders = emailVendor.deliveryOrders.find(
        (deliveryOrder) => deliveryOrder.data.id === order.data.id
    );
    const isInAddedOrders = emailVendor.addedOrders.find(
        (addedOrder) => addedOrder.data.id === order.data.id
    );
    return !isInDeliveryOrders && !isInAddedOrders;
}

function getOrdersThatCanBeAdded(emailVendor) {
    return emailVendor.orders
        .sort((a, b) => a.data.name.localeCompare(b.data.name))
        .filter((order) => filterDeliveryOrders(emailVendor, order));
}

const DeliveryOrdersUtils = {
    extractInventoryQty,
    extractOrderName,
    extractDeliveryQty,
    extractDeliveryUnitName,
    extractConversion,
    isValidState,
    filterForValidDelivery,
    filterOutAllVendorsOption,
    findIndexOfSelectedVendor,
    clearVendorSelection,
    findSelectedVendor,
    extractDeliveryDisplay,
    getOrderQuantity,
    createOrdersOffSiteTransactions,
    isNonEmptyOrder,
    extractOrders,
    extractNonEmptyOrders,
    sortOrders,
    isOffSiteVendor,
    getOrdersThatCanBeAdded,
};

export default DeliveryOrdersUtils;
