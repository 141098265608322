import {
    ADMINS,
    DEVS,
    GUESTS,
    KITCHEN_DELIVERY_USERS,
    MANAGERS,
    ORDERS_DELIVERY_USERS,
    ON_HAND_UNLOCK,
    ORDERS_ADMIN,
} from "./AppUserGroupsData";

function checkGroup(groupName, groups) {
    return groups.includes(groupName);
}

function isDev(groups) {
    return checkGroup(DEVS, groups);
}

function isAdmin(groups) {
    return checkGroup(ADMINS, groups);
}

function isOrdersAdmin(groups) {
    return checkGroup(ORDERS_ADMIN, groups);
}

function isManager(groups) {
    return checkGroup(MANAGERS, groups);
}

function isKitchenDelivery(groups) {
    return checkGroup(KITCHEN_DELIVERY_USERS, groups);
}

function isOrdersDelivery(groups) {
    return checkGroup(ORDERS_DELIVERY_USERS, groups);
}

function isOnHandUnlock(groups) {
    return checkGroup(ON_HAND_UNLOCK, groups);
}

// this filter is required whenever an user only has access to a specific restaurant
function getUserRestaurantWithFilter(restaurants, userGroups) {
    // todo: must be under food section
    if (
        isDev(userGroups) ||
        isAdmin(userGroups) ||
        isKitchenDelivery(userGroups) ||
        isOrdersAdmin(userGroups) ||
        isOrdersDelivery(userGroups)
    ) {
        return { restaurants };
    }

    const userRestaurants = restaurants.filter(({ data: { usersGroup } }) =>
        userGroups.includes(usersGroup)
    );

    if (userRestaurants.length !== 1) {
        throw Error(
            "Non-admin users should have access to exactly one restaurant! Please check user roles."
        );
    }

    restaurants = [userRestaurants[0]];
    return { restaurants, restaurantFilter: { restaurantId: { eq: restaurants[0].data.id } } };
}

function hasDevAccess(userGroups) {
    return isDev(userGroups);
}

function hasAdminAccess(userGroups) {
    return isAdmin(userGroups) || hasDevAccess(userGroups);
}

function hasDataEditorAdminAccess(userGroups) {
    return isOrdersAdmin(userGroups) || isAdmin(userGroups) || hasDevAccess(userGroups);
}

function hasManagerAccess(userGroups) {
    return isManager(userGroups) || hasAdminAccess(userGroups);
}

function hasNonGuestAccess(userGroups) {
    return userGroups?.[0] && userGroups[0] !== GUESTS;
}

const AppUserGroups = {
    hasDevAccess,
    hasAdminAccess,
    hasDataEditorAdminAccess,
    hasManagerAccess,
    hasNonGuestAccess,
    isOnHandUnlock,
    getUserRestaurantWithFilter,
};

export default AppUserGroups;
