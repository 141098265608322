import {
    Order,
    OrderArea,
    OrderUnit,
    OrderVendor,
    OrderInventory,
    OrderInventoryMeta,
} from "./../../graphql/API";
import AppDayModel from "../../components/day/AppDayModel";
import { Model } from "../../model/Model";
import { ModelConfig } from "../../model/ModelConfig";
import { ResourceColumnDate } from "../../resources/columns/ResourceColumnDate";
import { ResourceColumnNumber } from "../../resources/columns/ResourceColumnNumber";
import { ResourceColumnSelect } from "../../resources/columns/select/ResourceColumnSelect";
import { ResourceColumnText } from "../../resources/columns/ResourceColumnText";
import { Resource } from "../../resources/Resource";
import { DataEditorState } from "../data-editor/types/DataEditorTypes";

/* ---------- Order ---------- */
const appOrderModel = new Model(
    new ModelConfig("Order", 10000, 500, "orders", "Orders", "Order", "orderId", [
        new ResourceColumnText("id", "Id").setHideOnAdd().setKey().setWidth("M"),
        new ResourceColumnSelect("orderAreaId", "Area")
            .setVisible()
            .setKey()
            .setSortDirection("ASC", 0)
            .setSortCallback((a, b) => parseInt(a) - parseInt(b))
            .setWidth("L"),
        new ResourceColumnText("name", "Name").setVisible().setEditable().setWidth("M"),
        new ResourceColumnNumber("par", "par").setVisible().setEditable().setWidth("S"),
        new ResourceColumnNumber("stockLimit", "Stock Limit")
            .setVisible()
            .setEditable()
            .setWidth("S"),
        new ResourceColumnNumber("alertThreshold", "Alert")
            .setVisible()
            .setEditable()
            .setWidth("S"),
        new ResourceColumnSelect("orderVendorId", "from").setVisible().setEditable().setWidth("S"),
        new ResourceColumnText("discount", "Discount").setVisible().setEditable().setWidth("S"),
        new ResourceColumnSelect("orderOnHandUnitId", "OnHand")
            .setVisible()
            .setResourceRefKeyAlias("orderUnitsId")
            .setEditable()
            .setWidth("S"),
        new ResourceColumnNumber("conversion", "conv.").setVisible().setEditable().setWidth("S"),
        new ResourceColumnSelect("orderDeliveryUnitId", "Delivered")
            .setVisible()
            .setResourceRefKeyAlias("orderUnitsId")
            .setEditable()
            .setWidth("S"),
        new ResourceColumnSelect("offSiteItemId", "Off-Site Item")
            .setWidth("S")
            .setVisible()
            .setEditable()
            .setHideOnAdd(),
        new ResourceColumnNumber("order", "#")
            .setHideOnAdd()
            .setEditable()
            .setVisible()
            .setWidth("XS")
            .setSortDirection("ASC", 1)
            .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
        new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
        new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
    ])
);

class AppOrder extends Resource {
    static getModel(): Model {
        return appOrderModel;
    }

    getModel(): Model {
        return appOrderModel;
    }

    constructor(data: Order) {
        super(data, appOrderModel);
    }
}

/* ---------- OrderArea ---------- */
class AppOrderAreaModel extends Model {
    refDisplay(data: OrderArea, dataEditorState?: DataEditorState) {
        if (dataEditorState) {
            return super.refChainDisplay(data, dataEditorState, "restaurantId");
        }
        return super.refDisplay(data);
    }
}

const appOrderAreaModel = new AppOrderAreaModel(
    new ModelConfig(
        "OrderArea",
        1000,
        200,
        "orderAreas",
        "Orders Areas",
        "OrderArea",
        "orderAreaId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnSelect("restaurantId", "Rest.")
                .setKey()
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnText("name", "Name").setVisible().setEditable().setWidth("M"),
            new ResourceColumnSelect("type", "Type", ["DRY GOODS", "KITCHEN"])
                .setVisible()
                .setWidth("XS")
                .setEditable()
                .setOptional(),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppOrderArea extends Resource {
    static getModel(): AppOrderAreaModel {
        return appOrderAreaModel;
    }

    getModel(): AppOrderAreaModel {
        return appOrderAreaModel;
    }

    constructor(data: OrderArea) {
        super(data, appOrderAreaModel);
    }
}

/* ---------- OrderUnits ---------- */
const appOrderUnitsModel = new Model(
    new ModelConfig(
        "OrderUnit",
        1000,
        200,
        "orderUnits",
        "Orders Units",
        "OrderUnit",
        "orderUnitsId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnText("name", "Name").setKey().setVisible().setWidth("S"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppOrderUnits extends Resource {
    static getModel(): Model {
        return appOrderUnitsModel;
    }

    constructor(data: OrderUnit) {
        super(data, appOrderUnitsModel);
    }
}

/* ---------- OrderVendors ---------- */
const appOrderVendorModel = new Model(
    new ModelConfig(
        "OrderVendor",
        1000,
        200,
        "orderVendors",
        "Orders Vendors",
        "OrderVendor",
        "orderVendorId",
        [
            new ResourceColumnText("id", "Id").setHideOnAdd().setKey(),
            new ResourceColumnText("name", "Name").setKey().setVisible().setWidth("S"),
            new ResourceColumnText("email", "Email").setVisible().setEditable().setWidth("M"),
            new ResourceColumnText("emailBcc", "BCC").setVisible().setEditable().setWidth("M"),
            new ResourceColumnNumber("isOffSite", "Off-Site")
                .setVisible()
                .setEditable()
                .setWidth("XS"),
            new ResourceColumnNumber("order", "#")
                .setHideOnAdd()
                .setEditable()
                .setVisible()
                .setWidth("XS")
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnText("lastUpdatedBy", "Updated By").setOptional(),
            new ResourceColumnNumber("lastUpdatedAt", "Update Time").setOptional(),
        ]
    )
);

class AppOrderVendor extends Resource {
    static getModel(): Model {
        return appOrderVendorModel;
    }

    getModel(): Model {
        return appOrderVendorModel;
    }

    constructor(data: OrderVendor) {
        super(data, appOrderVendorModel);
    }
}

/* ---------- OrderInventory ---------- */
class AppOrderInventoryModel extends Model {
    getFilter(appState: any) {
        return {
            day: { eq: appState.day.data.time || AppDayModel.getTodayUnixTime() },
        };
    }
}

const appOrderInventoryModel = new AppOrderInventoryModel(
    new ModelConfig(
        "OrderInventory",
        200000,
        200,
        "orderInventories",
        "On Hand Today",
        "OrderInventory",
        "orderInventoryId",
        [
            new ResourceColumnText("orderId", "Name")
                .setVisible()
                .setWidth("S")
                .setKey()
                .setSortDirection("ASC", 0)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b)),
            new ResourceColumnDate("day", "Day").setKey(),
            new ResourceColumnNumber("quantity", "Qty")
                .setOptional()
                .setVisible()
                .setEditable()
                .setWidth("XS"),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional().setVisible().setWidth("XS"),
            new ResourceColumnDate("lastUpdatedAt", "At")
                .setOptional()
                .setVisible()
                .setWidth("S")
                .setSortDirection("ASC", 1),
        ]
    )
);

class AppOrderInventory extends Resource {
    static getModel(): AppOrderInventoryModel {
        return appOrderInventoryModel;
    }

    getModel(): AppOrderInventoryModel {
        return appOrderInventoryModel;
    }

    constructor(data: OrderInventory) {
        super(data, appOrderInventoryModel);
    }
}

/* ---------- OrderInventoryMeta ---------- */
const appOrderInventoryMetaModel = new Model(
    new ModelConfig(
        "OrderInventoryMeta",
        10000,
        200,
        "ordersInventoriesMeta",
        "On Hand Records",
        "OrderInventoryMeta",
        "orderInventoryMetaId",
        [
            new ResourceColumnDate("day", "Day", "D MMM")
                .setKey()
                .setSortDirection("DESC", 0)
                .setSortCallback((a, b) => parseInt(b) - parseInt(a))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnSelect("restaurantId", "Rest")
                .setKey()
                .setSortDirection("ASC", 1)
                .setSortCallback((a, b) => parseInt(a) - parseInt(b))
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnSelect("status", "Status", ["UNLOCKED", "LOCKED"])
                .setEditable()
                .setOptional()
                .setVisible()
                .setWidth("XS"),
            new ResourceColumnText("storageKey", "S3 Path")
                .setEditable()
                .setVisible()
                .setWidth("L"),
            new ResourceColumnText("completion", "%").setOptional().setWidth("XS"),
            new ResourceColumnText("lastUpdatedBy", "By").setOptional().setWidth("XS"),
            new ResourceColumnDate("lastUpdatedAt", "At").setOptional().setWidth("S"),
        ]
    )
);

class AppOrderInventoryMeta extends Resource {
    static getModel(): Model {
        return appOrderInventoryMetaModel;
    }

    getModel(): Model {
        return appOrderInventoryMetaModel;
    }

    constructor(data: OrderInventoryMeta) {
        super(data, appOrderInventoryMetaModel);
    }
}

export {
    AppOrder,
    AppOrderArea,
    AppOrderUnits,
    AppOrderVendor,
    AppOrderInventory,
    AppOrderInventoryMeta,
};
