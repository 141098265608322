import Papa from "papaparse";
import SalesParser from "./SalesParser";

const MenuSalesParser = (onUpdate, reader) => {
    // TODO: expose these column names in settings
    // TODO: throw meaningful errors
    // TODO: check that all rows have the same size
    // TODO: test if quantities can be parsed
    try {
        let salesData = Papa.parse(reader.result).data;
        console.log("Raw sales data: \n", salesData);

        salesData = SalesParser.parseRawSales(salesData);
        console.log("Parsed sales data: \n", salesData);

        salesData = SalesParser.sortParsedSales(salesData);
        console.log("Sorted sales data: \n", salesData);

        salesData = SalesParser.filterSortedSales(salesData);
        console.log("Filtered sales data: \n", salesData);

        salesData = SalesParser.mergeFilteredSales(salesData);
        console.log("Merged sales data: \n", salesData);

        return onUpdate(salesData);
    } catch (e) {
        throw Error(`Cannot parse given file because of ${e}`);
    }
};

export default MenuSalesParser;
